@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoons/icomoon.eot?gm59n8');
    src:  url('../fonts/icomoons/icomoon.eot?gm59n8#iefix') format('embedded-opentype'),
      url('../fonts/icomoons/icomoon.ttf?gm59n8') format('truetype'),
      url('../fonts/icomoons/icomoon.woff?gm59n8') format('woff'),
      url('../fonts/icomoons/icomoon.svg?gm59n8#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-arrow-down:before {
    content: "\e900";
    color: #fff;
  }
  .icon-arrow-up:before {
    content: "\e901";
    color: #fff;
  }
  .icon-arrow-down-two:before {
    content: "\e902";
  }
  .icon-cross:before {
    content: "\e903";
  }
  .icon-delete:before {
    content: "\e904";
  }
  .icon-edit:before {
    content: "\e905";
  }
  .icon-tick:before {
    content: "\e906";
  }
  
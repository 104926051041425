html,
body {
  height: 100%;
  font-family: 'Khula', sans-serif !important;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 130px;

  @media (min-width: 768px) {
    padding-top: 0;
  }
}

.login-bg {
  background-image: url('../images/login-bg.png');
  background-repeat: no-repeat;
  min-height: 480px;
  background-position: center;
  background-size: contain;
}

.logo {
  padding-top: 20px;
}

.login-form {
  margin-top: 50px;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 45px;

    &.last {
      margin-bottom: 0;
    }

    .form-label {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #022144;
      opacity: 0.8;
      margin: 0;
      padding: 0;
    }

    .form-control {
      border: 1px solid #022144;
      box-sizing: border-box;
      border-radius: 4px;
      height: 48px;
      padding: 0 15px;
      font-size: 1.5rem;
    }
  }

  a {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #022144;
    opacity: 0.6;
    display: block;
    margin-top: 12px;
    margin-bottom: 40px;
  }

  .btn {
    background: #3486cd;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    padding: 10px 66px;
  }

  input:placeholder {
    font-size: 18px;
    line-height: 29px;
    color: #022144;
    opacity: 0.6;
  }
}

.btn {
  &.btn-primary {
    border-color: #4ad295;
    background: #4ad295;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 18px;

    .tick {
      padding-right: 8px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px;

  .main-wrapper {
    padding-top: 60px;
  }
  .navbar {
    margin-bottom: 40px;
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(2, 33, 68, 0.8);
    font-size: 18px;
    padding: 20px 0;
    @media (min-width: 992px) {
      padding: 10px 20px;
    }
    &:hover {
      color: #0b6abc;
    }
  }
}

.stepper {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 510px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-style: dashed;
    color: #e9ecf0;
    z-index: -1;
    border-width: thin;
  }

  @media (min-width: 768px) {
    padding-top: 0;
  }

  li {
    background: #e9ecf0;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    /* line-height: 35px; */
    text-align: center;
    color: #ffffff;

    &.active {
      background-color: rgba($color: #3486cd, $alpha: 0.8);
    }

    &.success {
      background: #fe0000;
      width: 50px;
      height: 50px;
    }
  }
}

.heading-wrapper {
  margin-bottom: 20px;

  h2 {
    &.main-heading {
      font-weight: normal;
      font-size: 28px;
      line-height: 48px;
      color: #0b6abc;
      margin: 0;
    }
  }
  h1 {
    &.main-heading {
      font-weight: normal;
      font-size: 32px;
      line-height: 48px;
      color: #0b6abc;
      margin: 0;
    }
  }

  p {
    &.page-count {
      font-size: 20px;
      line-height: 48px;
      color: #022144;
    }
  }
}

.btn-wrapper {
  background: #f2f5f9;
  padding: 28px 0;

  i {
    background: #3486cd;
    border-radius: 6px;
    font-size: 12px;
    padding: 16px 13px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.section-top {
  padding-bottom: 15px;
}
.section-top,
.section-bottom {
  label {
    display: block;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: rgba($color: #022144, $alpha: 0.8);
    margin: 0;
    padding: 0;
  }

  select {
    padding: 0 10px;
    background-color: #fff;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  input,
  select {
    border: 1px solid rgba($color: #022144, $alpha: 0.3);
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    min-height: 48px;
    padding: 0 15px;
    &:focus {
      outline: none;
    }
    font-size: 1.5rem;
  }

  .step3 {
    align-items: flex-end;
    padding-bottom: 10px;

    p {
      margin: 0;
      font-weight: normal;
      font-size: 18px;
      line-height: 29px;
      padding-right: 40px;
      color: rgba($color: #022144, $alpha: 0.6);
    }

    input {
      width: inherit;
      min-height: inherit;
    }

    .form-check {
      padding-right: 30px;
    }

    label {
      line-height: 27px;
      padding-left: 11px;
    }
  }

  .step8 {
    margin-top: 13px;
  }

  .form-check-input {
    width: 24px !important;
    height: 24px;
    margin-top: 0;
    margin-left: 0;
    left: 0;
  }

  .arrow {
    &:before {
      content: '\e900';
      position: absolute;
      bottom: 32px;
      width: 0;
      height: 0;
      z-index: 1;
      font-family: 'icomoon' !important;
      right: 45px;
      color: #9aa6b4;
      font-size: 12px;
      pointer-events: none;
    }
  }
}

.section-top {
  .section-top-actions {
    margin-top: 30px;
    margin-bottom: 80px;

    .edit,
    .delete {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #022144, $alpha: 0.1);
    }
  }
}

.section-bottom {
  label,
  select {
    color: rgba($color: #022144, $alpha: 0.2);
  }

  input,
  select {
    pointer-events: none;
  }

  select {
    option {
      color: rgba($color: #022144, $alpha: 0.2);
    }
  }

  .step3 {
    p {
      color: rgba($color: #022144, $alpha: 0.2);
    }
  }
}

form {
  .form-check-input {
    width: 24px !important;
    height: 24px;
    margin-top: 0;
    margin-left: 0;
    left: 0;
  }

  .form-check-label {
    padding-left: 11px;
  }
}

.form-check-input {
  cursor: pointer;
}

.prescriptions-details {
  label {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: rgba($color: #022144, $alpha: 0.8);
  }
  .form-check {
    display: flex;
    align-items: center;
  }
}

.drugs-details {
  margin-bottom: 20px;

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba($color: #022144, $alpha: 0.4);
  }
}

.summary-wrapper {
  .summary-intro {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba($color: #022144, $alpha: 0.4);
    margin: 0;

    strong {
      color: #000;
    }

    &.section-two-bottom {
      padding-top: 34px;
    }
    &.section-black-text {
      color: #000000;
    }
  }

  .summary-details {
    border: 1px solid rgba($color: #022144, $alpha: 0.2);
    box-sizing: border-box;
    border-radius: 2px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 20px;

    .edit {
      cursor: pointer;
    }

    h5 {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: rgba($color: #022144, $alpha: 0.8);

      &.highlited {
        color: rgba($color: #fe0000, $alpha: 0.8);
      }
    }
  }

  .btn {
    padding: 13px 18px;
  }

  .section-two-intro {
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
    color: #022144;
    margin: 0;

    &.small {
      color: rgba($color: #022144, $alpha: 0.8);
      font-weight: normal;
    }
  }

  .section-two-head {
    padding-bottom: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba($color: #022144, $alpha: 0.2);
  }
}

.popup-form {
  padding: 10px 25px 10px 10px;
  position: relative;

  .cross {
    position: absolute;
    right: 0;
    top: 0;
  }

  h5 {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: rgba($color: #022144, $alpha: 0.8);
  }

  label {
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    color: rgba($color: #022144, $alpha: 0.9);
  }
}

.add-drugs-info {
  .drug-intro {
    margin-bottom: 13px;
  }

  .form-check {
    .form-check-input {
      min-height: 30px;
    }
  }

  .btn {
    padding: 13px 21px;
  }
  .spacer {
    margin-bottom: 45px;
  }
}

.drug-label {
  background: #f2f5f9;
  border-radius: 16px;
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #022144;
  padding: 7px 8px 6px 12px;
  margin-right: 10px;
  cursor: pointer;

  i {
    background: rgba($color: #022144, $alpha: 0.1);
    color: rgba($color: #022144, $alpha: 0.3);
    margin-left: 12px;
    font-size: 8px;
    border-radius: 50%;
    padding: 4px 5px;
  }
}

.top-banner {
  padding-top: 67px;
  padding-bottom: 58px;
}

.success-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;

  h1 {
    padding-top: 18px;
    font-weight: 600;
    font-size: 28px;
    line-height: 48px;
    color: #0b6abc;
    margin: 0;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #022144;
    opacity: 0.8;
    margin: 0;
    max-width: 559px;
  }

  .btn {
    margin-top: 25px;
    padding: 13px 16px;
  }
  img {
    max-width: 100%;
  }
}
// .medicine-container {
//   display: inline-flex;
//   width: 60em;
//   align-items: center;
// }
// .medicine-container-name {
//   width: 50% !important;
//   flex: none !important;
// }
// .medicine-container-name-input {
//   max-width: none !important;
//   flex: none !important;
// }
// .medicine-container-dosage {
//   width: 50%;
//   display: flex;
//   min-height: 48px;
// }
// .medicine-container-dosage-group {
//   max-width: none !important;
//   width: 50% !important;
//   flex: none !important;
//   margin-bottom: 0px !important;
// }
.payment-plan-expired {
  color: red;
}
.dropdown-wrapper {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 15px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: #f8f9fa;
}
.dropdown {
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #f8f9fa;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 0.5rem 0;
  &:hover,
  &:focus {
    background-color: #e9ecf0;
  }
  &:focus {
    outline: none;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: rgba(2, 33, 68, 0.8);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-size: 1.5rem;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: #16181b;
    }
    &:focus {
      outline: none;
    }
  }
}

.drugs-detail {
  .proceed {
    border: 1px solid rgba($color: #022144, $alpha: 0.4);
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    background-color: transparent;
    padding: 11px 23px;
    margin-bottom: 20px;
    color: rgba($color: #022144, $alpha: 0.6);

    @media (min-width: 768px) {
      margin-right: 30px;
      margin-bottom: 0;
    }

    &.secondary {
      border: 1px solid #3486cd;
      color: #3486cd;
    }
  }

  .edit,
  .delete {
    cursor: pointer;
    margin-right: 20px;
  }

  .last {
    margin-top: 60px;
  }
}
.last {
  span {
    display: block;
    margin-top: 10px;
    @media (min-width: 768px) {
      display: inline-block;
      margin-top: 0;
      margin-left: 10px;
    }
  }
}
.error {
  color: #fe0000 !important;
}

//css hacks
@-moz-document url-prefix() {
  .selector {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

@media all and (min--moz-device-pixel-ratio: 0) and (min-resolution: 1280px) {
  .selector {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .selector {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

@media all and(-webkit-min-device-pixel-ratio:0) and(min-resolution: 1280px) {
  .selector {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

@media not all and (-webkit-min-device-pixel-ratio: 0) {
  .selector {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

@media all and(-webkit-min-device-pixel-ratio:0) and(min-resolution: 1280px) {
  .selector {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

:root .selector {
  select::-ms-expand {
    display: none;
  }

  select {
    -webkit-appearance: none;
    appearance: none;
  }
}

@supports (-ms-ime-align: auto) {
  .selector {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

.toast {
  z-index: 2;
  .toast-header {
    .close {
      display: none;
    }
    img {
      cursor: pointer;
    }
  }
}
.no-class label {
  font-weight: normal;
}
.yes-class label {
  font-weight: bold;
}

.personQuantity {
  margin-left: 10px;
  width: 50px;
  height: 30px;
  border-color: '#dbdbd6';
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  text-align: center;
}
.personQuantity:focus {
  outline: none;
}

.align-grid-wrapper {
  flex-direction: column;
  align-items: flex-start !important;
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: flex-end !important;
  }
  .align-grid {
    display: flex;
  }
}

.step2-details {
  h3,
  h4 {
    font-size: 20px;
  }
  h2 {
    font-size: 32px;
    line-height: 48px;
    color: #0b6abc;
  }
  p {
    font-size: 18px;
  }

  li {
    font-size: 1.25rem;
  }
}

.step2-details .hmm-summary-heading {
  font-size: 1.75rem !important;
  color: #1f3763;
}

.medical-list {
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  color: #0b6abc;
}
.margin-bottom {
  margin-bottom: 1rem;
}
